
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">

      <h3 style={{ padding: '0 200px', fontFamily: 'Rock Salt, sans-serif'  }}>Winter Items Collected During Stride into the Spirit of Giving!</h3>
      <h5 style={{ padding: '0 200px' }}>
          On December 10th, the warmth of generosity shone brightly at our "Stride into the Spirit of Giving!" event. The data vividly illustrate the incredible outpouring of support from our community.
      </h5>

      <h5 style={{ padding: '0 200px' }}>
          Below, you can see the impact of your contributions! 

          A heartfelt thank you to every donor, volunteer, and supporter who made this event an overwhelming success. Your generosity will keep many of our new neighbors warm and spread hope in our community. Together, we've made a remarkable impact, embodying the true spirit of giving!
      </h5>
        <div class="card" style={{marginBottom: "50px"}}>
                <div class="stat">
                    <div class="value">1,390</div>
                    <div class="type">Items Donated</div>
                </div>
                <div class="stat" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                    <div class="value">193</div>
                    <div class="type">Donors</div>
                </div>
                <div class="stat">
                    <div class="value">75</div>
                    <div class="type">5k/1 Mile Participants</div>
                </div>
        </div>
            

        <iframe 
          title="Stride Into Giving"
          width="1200" 
          height="900" 
          src="https://lookerstudio.google.com/embed/reporting/0d16814c-f199-4123-97c1-c3447d6a9ec0/page/tEnnC" 
          frameBorder="0" 
          style={{ border: '0' }} 
          allowFullScreen
        ></iframe>
       
      </header>
    </div>
  );
}

export default App;
